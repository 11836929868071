//import 'normalize.css';
import "./styles/all.scss";

import Vue from "vue/dist/vue";
import focusTrap from "focus-trap";

if (module.hot) {
  module.hot.accept();
}

//some nonsense needed to pass data attrs to root instance from CMS
const menuContainer = document.getElementById("offCanvasMenu");
const parents = JSON.parse(menuContainer.dataset.parentIndices);
const offCanvasMenuData = {
  open: false,
  offcanvasTrap: null,
};
for (let i = 0; i < parents.length; i++) {
  offCanvasMenuData["childOf" + parents[i]] = false;
}
new Vue({
  el: "#offCanvasMenu",
  delimiters: ["${", "}"],
  data: function () {
    return offCanvasMenuData;
  },
  mounted: function () {
    this.offcanvasTrap = focusTrap("#offCanvasMenu", {
      clickOutsideDeactivates: true,
    });
    const body = document.querySelector("body");
    body.addEventListener("click", (e) => {
      if (!this.$root.$el.contains(e.target)) this.closeAll();
    });
  },
  methods: {
    afterEnter() {
      this.offcanvasTrap.activate();
    },
    afterLeave() {
      this.offcanvasTrap.deactivate();
    },
    toggleMenu(state) {
      this.open = state;
    },
    closeAll() {
      parents.forEach((i) => {
        this["childOf" + i] = false;
      });
    },
    toggleChildren(index) {
      //if the item we're toggling isn't already open, close all items
      if (!this["childOf" + index]) {
        this.closeAll();
      }
      this["childOf" + index] = !this["childOf" + index];
    },
  },
});

// set horizontal scroll position for any .pageNav--x-scroll
document.querySelectorAll(".pageNav--x-scroll").forEach((navCont) => {
  const navContBBox = navCont.getBoundingClientRect();
  //get the actual, scrollable ul
  const nav = navCont.querySelector(".pageNav__navItems");
  //get the anchor with the active class
  const active = nav.querySelector(".pageNav__navLink--active");

  //scroll the nav with appropriate offsets to center the active item
  if (active)
    nav.scrollLeft =
      active.offsetLeft - (nav.clientWidth / 2 - active.clientWidth / 2);

  //add an event listener to adjust the opacity of the :before and :after overlay when the scroll is near the start or end
  nav.addEventListener("scroll", (e) => {
    setXScrollOverlay(e.target);
  });
  //check the opacity on load in case we start at the start or end
  setXScrollOverlay(nav);

  //wire up dropdowns
  const btns = navCont.querySelectorAll("button.pageNav__navLink");
  if (window.outerWidth < 990) closeAllDropdowns();
  btns.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      //set position
      setSubNavPos(btn);

      //on click, close all dropdowns except the current button's
      closeAllDropdowns(btn);
      btn.classList.toggle("pageNav__navLink--open");
      e.stopPropagation();

      //add or remove event 'close all' listener
      if (e.target.classList.contains("pageNav__navLink--open")) {
        //we just opened the nav, so add a closing listener
        document.addEventListener("click", handleClickOutside);
      } else document.removeEventListener("click", handleClickOutside);
    });
  });

  //adjust x-position of sub nav on scroll
  nav.addEventListener("scroll", (e) => {
    const btn = document.querySelector(".pageNav__navLink--open");
    if (btn) setSubNavPos(btn);
  });
  function setSubNavPos(btn) {
    const li = btn.closest("li");
    const liBBox = li.getBoundingClientRect();
    const leftPos = liBBox.left - navContBBox.left;
    li.style.setProperty("--anchor-left", leftPos + "px");
  }
  function closeAllDropdowns(except) {
    btns.forEach((btn) => {
      if (btn !== except) btn.classList.remove("pageNav__navLink--open");
      document.removeEventListener("click", handleClickOutside);
    });
  }
  const handleClickOutside = function (e) {
    const subNav = e.target.closest(".pageNav__navSubItems--dropdown");
    if (subNav) {
      const btn = subNav.previousElementSibling;
      if (btn.classList.contains("pageNav__navLink--open")) {
        return;
      }
    }

    closeAllDropdowns();
  };
});
function normalizeOpacity(val) {
  return val > 1 ? 1 : val;
}
function setXScrollOverlay(target) {
  const multiplier = 0.04;
  const startOpacity = target.scrollLeft * multiplier;
  const endOpacity =
    (target.scrollWidth - (target.offsetWidth + target.scrollLeft)) *
    multiplier;
  target.style.setProperty(
    "--startFadeOpacity",
    normalizeOpacity(startOpacity)
  );
  target.style.setProperty("--endFadeOpacity", normalizeOpacity(endOpacity));
}
